import React, { type FC, useState } from 'react';
import cn from 'classnames';
import { useImageLoaded } from '@hooks/useImageLoaded';
type extensionTypes = 'jpg' | 'webp' | 'png';
interface ImgProps extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src' | 'srcSet'> {
  path: string;
  cdnId: string;
  boxClassName: string;
  alt: string;
  resolutions?: 1 | 2 | 3;
  availableExtensions?: extensionTypes[];
  defaultLoad?: boolean;
}

/**
 *  Компонент для автоматического фоллбэка/выбора расширения картинок.
 *  @example
 *  <Img path='/artist/avatars' cdnId={artist.avatarid} availableExtensions={['jpg', 'png', 'webp']}  alt={'Какой-то альт который мы храним'}/>
 *
 *  с этими пропсами компонент сгенерит srcSet на картинку с 3 разрешениями, выберет одно из разрешений
 *  из доступных(приоритет - webp), сгенерит срц для картинки вида:
 *  @example
 *  `https://ourCdn.url${path}/${cdnId}.${extension}`
 * */

const generateSrcset = (cdnId: string, extension: extensionTypes, path: string, platformDecorator: '.mobile' | '.original', resolutions = 3) => {
  let srcset = '';
  for (let i = 1; i <= resolutions; i++) {
    const idDecorator = i > 1 ? `@${i}x` : '';
    if (i === resolutions) {
      srcset += `https://chikoroko.b-cdn.net${path}/${cdnId}${platformDecorator}${idDecorator}.${extension} ${i}x`;
    } else {
      srcset += `https://chikoroko.b-cdn.net${path}/${cdnId}${platformDecorator}${idDecorator}.${extension} ${i}x, `;
    }
  }
  return srcset;
};
const extensions: extensionTypes[] = ['webp', 'png', 'jpg'];
const ImgMemo: FC<ImgProps> = ({
  path,
  cdnId,
  resolutions = 3,
  availableExtensions = ['webp', 'png'],
  boxClassName,
  defaultLoad,
  alt,
  onLoad,
  onError,
  ...imgProps
}) => {
  const {
    ref,
    loaded,
    onLoad: onImageLoad
  } = useImageLoaded();
  const [isError, setIsError] = useState(false);
  return <div className={cn('_ibg', boxClassName)} data-sentry-component="ImgMemo" data-sentry-source-file="Img.tsx">
            <picture data-sentry-element="picture" data-sentry-source-file="Img.tsx">
                {!isError && extensions.filter(extension => availableExtensions.includes(extension)).map(extensionType => <React.Fragment key={extensionType}>
                            <source srcSet={generateSrcset(cdnId, extensionType, path, '.original', resolutions)} media='(min-width: 767.98px)' type={`image/${extensionType}`} />
                            <source srcSet={generateSrcset(cdnId, extensionType, path, '.mobile', resolutions)} media='(max-width: 767.98px)' type={`image/${extensionType}`} />
                        </React.Fragment>)}
                <img ref={ref} className={cn('_img', loaded && '_img_loaded', imgProps.className)} src={'/1x1.png'} alt={alt} loading={!defaultLoad ? 'lazy' : undefined} onLoad={event => {
        onLoad?.(event);
        onImageLoad();
      }} onError={event => {
        onError?.(event);
        setIsError(true);
      }} {...imgProps} />
            </picture>
        </div>;
};
export const Img = React.memo(ImgMemo);