import React, { FC } from 'react';
import s from './mobileNavLink.module.scss';
import Link from 'next/link';
import { INavLink, LinksEnum } from '@specs/navigation';
import cn from 'classnames';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { useSession } from 'next-auth/react';
interface Props extends Omit<INavLink, 'text'> {
  withPaddings?: boolean;
  isActive: boolean;
}
export const MobileNavLink: FC<Props> = ({
  withPaddings,
  icon,
  active_icon,
  href,
  isActive,
  onlyAuthLink,
  isDisabled,
  ...props
}) => {
  const {
    status
  } = useSession();
  return <Link className={cn(s.mobileNavLink, isActive && s.mobileNavLink_active)} href={status === 'unauthenticated' && onlyAuthLink ? LinksEnum.SIGN_IN : href} {...props} data-sentry-element="Link" data-sentry-component="MobileNavLink" data-sentry-source-file="MobileNavLink.tsx">
            <SpriteIcon {...isActive ? active_icon : icon} color={isActive ? '--Thamar-Black' : '--waiting'} data-sentry-element="SpriteIcon" data-sentry-source-file="MobileNavLink.tsx" />
        </Link>;
};