import { RefObject, useEffect, useRef, useState } from 'react'

interface useImageLoadedReturn {
    ref: RefObject<HTMLImageElement>
    loaded: boolean
    onLoad: () => void
}

export const useImageLoaded = (): useImageLoadedReturn => {
    const [loaded, setLoaded] = useState(false)
    const ref = useRef<HTMLImageElement>(null)

    const onLoad = () => {
        setLoaded(true)
    }

    useEffect(() => {
        if (ref.current && ref.current.complete) {
            onLoad()
        }
    }, [ref.current])

    return { ref, loaded, onLoad }
}
