import React, { FC } from 'react';
import s from './mobileNavigation.module.scss';
import cn from 'classnames';
import { EXPLORE_BASE_URL, INavLink, LinksEnum } from '@specs/navigation';
import { useRouter } from 'next/router';
import { MobileNavLink } from '@components/MobileNavLink/MobileNavLink';
import { profile } from '@specs/specs';
import { useProfile } from '@hooks/TRPC_Hooks/useProfile';
interface Props {}
export const getNavLinks = (shortId: profile['shortid'] | undefined) => {
  const navigationLinks: Omit<INavLink, 'text'>[] = [{
    icon: {
      name: 'feed'
    },
    active_icon: {
      name: 'feed_active'
    },
    href: LinksEnum.FEED_POSTS
  }, {
    icon: {
      name: 'explore'
    },
    active_icon: {
      name: 'explore_active'
    },
    href: LinksEnum.EXPLORE
  }, {
    icon: {
      name: 'plus'
    },
    active_icon: {
      name: 'plus'
    },
    href: LinksEnum.POST_CREATE,
    onlyAuthLink: true
  },
  // {
  //     icon: {
  //         name: 'games',
  //     },
  //     active_icon: {
  //         name: 'games_active',
  //     },
  //     href: LinksEnum.GAMES,
  //     onlyAuthLink: true,
  // },
  {
    icon: {
      name: 'user'
    },
    active_icon: {
      name: 'user_active'
    },
    href: {
      pathname: LinksEnum.USER,
      query: {
        slug: shortId
      }
    },
    onlyAuthLink: true,
    isDisabled: typeof shortId !== 'string'
  }];
  return navigationLinks;
};
export const MobileNavigation: FC<Props> = ({}) => {
  const router = useRouter();
  const {
    data: profile
  } = useProfile();
  const navigationLinks = getNavLinks(profile?.shortid);
  return <div className={cn(s.mobileNavigation, '_mobile')} data-sentry-component="MobileNavigation" data-sentry-source-file="MobileNavigation.tsx">
            <div className={cn(s.mobileNavigation__container, '_container')}>
                <div className={s.mobileNavigation__grid}>
                    {navigationLinks.map(link => {
          const url = typeof link.href === 'string' ? link.href : link.href.pathname;
          const isActive = url === LinksEnum.FEED_POSTS ? router.pathname === LinksEnum.FEED_TOYS || router.pathname === LinksEnum.FEED_POSTS || router.pathname === LinksEnum.FEED_PHYSICAL : url === LinksEnum.EXPLORE ? router.pathname.includes(EXPLORE_BASE_URL) : url === router.pathname;
          return <MobileNavLink key={url} {...link} isActive={isActive} onClick={e => {
            if (link.isDisabled) e.preventDefault();
          }} />;
        })}
                </div>
            </div>
        </div>;
};