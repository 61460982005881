import React, { FC, useState } from 'react';
import s from './header.module.scss';
import dynamic from 'next/dynamic';
import RToysLogoHover from '@assets/RToysLogoHover.json';
import Link from 'next/link';
import { LinksEnum } from '@specs/navigation';
const Lottie = dynamic(() => import('react-lottie-player'), {
  ssr: false,
  loading: () => <div className={s.header__logo} />
});
interface Props {}
export const HeaderLogo: FC<Props> = ({}) => {
  const [playLogo, setPlayLogo] = useState(false);
  return <Link href={LinksEnum.FEED_POSTS} data-sentry-element="Link" data-sentry-component="HeaderLogo" data-sentry-source-file="HeaderLogo.tsx">
            <Lottie className={s.header__logo} animationData={RToysLogoHover} play={playLogo} onMouseEnter={() => {
      setPlayLogo(true);
    }} onLoopComplete={() => {
      setPlayLogo(false);
    }} loop data-sentry-element="Lottie" data-sentry-source-file="HeaderLogo.tsx" />
        </Link>;
};