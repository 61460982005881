import React, { FC } from 'react';
import s from './header.module.scss';
import { EXPLORE_BASE_URL, INavLink, LinksEnum } from '@specs/navigation';
import cn from 'classnames';
import { HeaderRight } from '@components/PageLayout/Header/HeaderRight';
import { useRouter } from 'next/router';
import { HeaderLogo } from '@components/PageLayout/Header/HeaderLogo';
import { useTranslation } from '@hooks/useTranslation';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { HEADER_ID } from '@specs/constants';
import { UiLink } from '@components/UIKit/UiLink/UiLink';
interface Props {
  showBackIconOnMobile?: boolean;
}
const navigationLinks: INavLink[] = [{
  text: 'header.feed',
  icon: {
    name: 'feed'
  },
  active_icon: {
    name: 'feed_active'
  },
  href: LinksEnum.FEED_POSTS
}, {
  text: 'header.explore',
  icon: {
    name: 'explore'
  },
  active_icon: {
    name: 'explore_active'
  },
  href: LinksEnum.EXPLORE
}
// {
//     text: 'header.games',
//     icon: {
//         name: 'games',
//     },
//     active_icon: {
//         name: 'games',
//     },
//     href: LinksEnum.GAMES,
// },
];
export const Header: FC<Props> = ({
  showBackIconOnMobile
}) => {
  const router = useRouter();
  const {
    translate: tr
  } = useTranslation();
  return <header className={s.header} id={HEADER_ID} data-sentry-component="Header" data-sentry-source-file="Header.tsx">
            <div className={cn(s.header__body, '_container')}>
                <div className={cn(s.header__left, showBackIconOnMobile && s.header__left_back)}>
                    {showBackIconOnMobile && <SpriteIcon className={'_mobile'} name={'arrow_right_medium'} width={36} height={36} onClick={router.back} />}
                    <HeaderLogo data-sentry-element="HeaderLogo" data-sentry-source-file="Header.tsx" />
                    <nav className={cn(s.header__navigation, '_desktop')}>
                        {navigationLinks.map(link => {
            const url = typeof link.href === 'string' ? link.href : link.href.pathname;
            const isActive = url === LinksEnum.FEED_POSTS ? router.pathname === LinksEnum.FEED_TOYS || router.pathname === LinksEnum.FEED_POSTS || router.pathname === LinksEnum.FEED_PHYSICAL : url === LinksEnum.EXPLORE ? router.pathname.includes(EXPLORE_BASE_URL) : url === router.pathname;
            return <UiLink key={link.text} href={link.href} icons={{
              left: {
                ...(isActive ? link.active_icon : link.icon)
              }
            }} isActive={isActive} style={'Thick'}>
                                    {tr({
                id: link.text
              })}
                                </UiLink>;
          })}
                    </nav>
                </div>
                <HeaderRight data-sentry-element="HeaderRight" data-sentry-source-file="Header.tsx" />
            </div>
        </header>;
};