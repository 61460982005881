import { Button } from '@components/UIKit/Button/Button';
import { useTranslation } from '@hooks/useTranslation';
import { PostEventState, submitPost } from '@services/posts/helpers';
import React, { FC, useEffect, useState } from 'react';
type PulishButtonProps = {};
export const PublishButton: FC<PulishButtonProps> = () => {
  const [buttonState, setButtonState] = useState<PostEventState>('disabled');
  const {
    translate: tr
  } = useTranslation();
  useEffect(() => {
    const submitListener = (e: CustomEvent<PostEventState>) => {
      setButtonState(e.detail);
    };
    document.addEventListener('post_edit', submitListener as EventListener);
    return () => {
      document.removeEventListener('post_edit', submitListener as EventListener);
    };
  }, []);
  return <Button id='publish_post_button' color={'Black'} size={'medium'} onClick={submitPost} disabled={buttonState === 'disabled' || buttonState === 'error'} loading={buttonState === 'loading'} data-sentry-element="Button" data-sentry-component="PublishButton" data-sentry-source-file="PulishButton.tsx">
            {tr({
      id: 'btn.publish'
    })}
        </Button>;
};