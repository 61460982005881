import React, { FC, MouseEvent, useState } from 'react';
import s from './uiLink.module.scss';
import Link, { LinkProps } from 'next/link';
import cn from 'classnames';
import { SpriteIcon, SpriteIconProps } from '@components/Sprites/SpriteIcon';
import { useRouter } from 'next/router';
import { INavLink } from '@specs/navigation';
export enum styleEnum {
  'Thin',
  'Regular',
  'Thick',
}
type styleType = keyof typeof styleEnum;
interface Props extends LinkProps {
  children: React.ReactNode;
  style: styleType;
  isActive: boolean;
  isDisabled?: INavLink['isDisabled'];
  icons?: {
    left?: SpriteIconProps;
    right?: SpriteIconProps;
  };
}
export const UiLink: FC<Props> = ({
  children,
  style,
  isActive,
  icons,
  href,
  isDisabled,
  ...props
}) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const clickHandler = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (isActive || isDisabled) return;
    setIsLoading(true);
    await router.push(href);
    setIsLoading(false);
  };
  return <Link className={cn(s.uiLink, isActive && s.uiLink_active, isLoading && s.uiLink_loading, isDisabled && s.uiLink_disabled, ({
    'Thin': s.uiLink_Thin,
    'Regular': s.uiLink_Regular,
    'Thick': s.uiLink_Thick
  } satisfies Record<styleType, string>)[style])} onClick={clickHandler} href={href} {...props} data-sentry-element="Link" data-sentry-component="UiLink" data-sentry-source-file="UiLink.tsx">
            {typeof icons?.left !== 'undefined' && <SpriteIcon {...icons.left} />}
            {children && <span>{children}</span>}
            {typeof icons?.right !== 'undefined' && <SpriteIcon {...icons.right} />}
        </Link>;
};