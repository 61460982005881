import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import s from './header.module.scss';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { Button } from '@components/UIKit/Button/Button';
import { useSession } from 'next-auth/react';
import cn from 'classnames';
import { MyAvatar } from '@components/PageLayout/Header/MyAvatar';
import { useRouter } from 'next/router';
import { LinksEnum } from '@specs/navigation';
import { bodyLockAdd, bodyLockRemove } from '@helpers/helpers';
import { useOnClickOutside } from '@hooks/useClickOutside';
import dynamic from 'next/dynamic';
import { PublishButton } from './PulishButton';
const Cart = dynamic(() => import('@components/Cart/Cart').then(module => module.Cart), {
  ssr: false
});
const BurgerMenu = dynamic(() => import('@components/BurgerMenu/BurgerMenu').then(module => module.BurgerMenu), {
  ssr: false
});
interface Props {}
export const HeaderRight: FC<Props> = ({}) => {
  const router = useRouter();
  const {
    status
  } = useSession();
  const {
    translate: tr
  } = useTranslation();
  const blockRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(blockRef, () => {
    setIsOpenMenu(false);
  });
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  useEffect(() => {
    isOpenMenu ? bodyLockAdd(true) : bodyLockRemove();
  }, [isOpenMenu]);
  const createNewPost = async () => {
    await router.push(LinksEnum.POST_CREATE);
  };
  const burgerHandler = () => {
    setIsOpenMenu(prev => !prev);
  };
  const getActiveButtons = () => {
    const pathname = router.pathname;
    if (pathname === LinksEnum.POST_CREATE) {
      return <PublishButton />;
    }
    return <Button id='add_new_post_header_button' className={'_desktop'} color={'Black'} size={'medium'} icons={{
      left: {
        name: 'plus'
      }
    }} onClick={createNewPost} data-sentry-element="Button" data-sentry-component="getActiveButtons" data-sentry-source-file="HeaderRight.tsx">
                {tr({
        id: 'btn.new.post'
      })}
            </Button>;
  };
  return <div className={s.header__right} ref={blockRef} data-sentry-component="HeaderRight" data-sentry-source-file="HeaderRight.tsx">
            <div className={s.header__actions}>
                {status === 'authenticated' && getActiveButtons()}
                <Cart data-sentry-element="Cart" data-sentry-source-file="HeaderRight.tsx" />

                {status === 'unauthenticated' && <Button id='login_button' size='medium' color='White' icons={{
        left: {
          name: 'user-inactive'
        }
      }} onClick={() => router.push(LinksEnum.SIGN_IN)}>
                        {tr({
          id: 'btn.login'
        })}
                    </Button>}
                <div className={cn(s.header__account, isOpenMenu && s.header__account_open)} onClick={burgerHandler}>
                    {status === 'authenticated' && <div className={s.header__avatar}>
                            <MyAvatar onClick={e => {
            e.preventDefault();
          }} />
                        </div>}

                    <SpriteIcon className={'_desktop'} name={'arrow_down_small'} width={20} height={20} color={'--black'} data-sentry-element="SpriteIcon" data-sentry-source-file="HeaderRight.tsx" />
                    <div className={cn(s.header__burger, '_mobile')}>
                        <SpriteIcon name={isOpenMenu ? 'close' : 'burger_menu'} width={20} height={20} color={'--black'} data-sentry-element="SpriteIcon" data-sentry-source-file="HeaderRight.tsx" />
                    </div>
                </div>
            </div>
            <BurgerMenu isOpenMenu={isOpenMenu} data-sentry-element="BurgerMenu" data-sentry-source-file="HeaderRight.tsx" />
        </div>;
};