import React, { FC, ReactNode, useEffect } from 'react';
import cn from 'classnames';
import { Header } from '@components/PageLayout/Header/Header';
import { Footer } from '@components/PageLayout/Footer/Footer';
import { MobileNavigation } from '@components/PageLayout/MobileNavigation/MobileNavigation';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { isPublicPage, LinksEnum } from '@specs/navigation';
import { HrefLangLinks } from '@components/SEO/HrefLangLinks';
interface Props {
  showBackIconOnMobile?: boolean;
  children: ReactNode;
}
export const PageLayout: FC<Props> = ({
  children,
  showBackIconOnMobile
}) => {
  const {
    status
  } = useSession();
  const {
    push,
    pathname
  } = useRouter();
  useEffect(() => {
    if (status !== 'unauthenticated') return;
    !isPublicPage(pathname) && push(LinksEnum.SIGN_IN);
  }, [status, pathname]);
  return <>
            <HrefLangLinks data-sentry-element="HrefLangLinks" data-sentry-source-file="PageLayout.tsx" />
            <Header showBackIconOnMobile={showBackIconOnMobile} data-sentry-element="Header" data-sentry-source-file="PageLayout.tsx" />
            <MobileNavigation data-sentry-element="MobileNavigation" data-sentry-source-file="PageLayout.tsx" />
            <main className={cn('_main')}>{children}</main>
            <Footer data-sentry-element="Footer" data-sentry-source-file="PageLayout.tsx" />
        </>;
};