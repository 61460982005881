import { INavLink, LinksEnum } from '@specs/navigation'
import { profile } from '@specs/specs'
import { SpriteIconProps } from '@components/Sprites/SpriteIcon'
import { gtagEventTrigger } from '@specs/helpers'
import { Dictionary } from '@hooks/useTranslation'

export const getNavLinks = (shortId: profile['shortid'] | undefined): INavLink[] => {
    return [
        {
            text: 'header.feed',
            icon: {
                name: 'feed',
            },
            active_icon: {
                name: 'feed',
            },
            href: LinksEnum.FEED_POSTS,
        },
        {
            text: 'header.explore',
            icon: {
                name: 'explore',
            },
            active_icon: {
                name: 'explore',
            },
            href: LinksEnum.EXPLORE,
        },
        // {
        //     text: 'header.games',
        //     icon: {
        //         name: 'games',
        //     },
        //     active_icon: {
        //         name: 'games',
        //     },
        //     href: LinksEnum.GAMES,
        // },
        {
            text: 'header.my.toys',
            icon: {
                name: 'toys',
            },
            active_icon: {
                name: 'toys',
            },
            href: {
                pathname: LinksEnum.USER_TOYS,
                query: { slug: shortId },
            },
            isDisabled: typeof shortId !== 'string',
        },
        {
            text: 'header.account.settings',
            icon: {
                name: 'settings',
            },
            active_icon: {
                name: 'settings',
            },
            href: LinksEnum.SETTINGS,
        },
        {
            text: 'header.faq',
            icon: {
                name: 'faq',
            },
            active_icon: {
                name: 'faq',
            },
            href: LinksEnum.FAQ,
        },
        {
            text: 'header.support',
            icon: {
                name: 'support',
            },
            active_icon: {
                name: 'support',
            },
            href: LinksEnum.SUPPORT,
        },
    ]
}

interface IMedia {
    href: string
    icon: SpriteIconProps
    onClick: () => void
}

export const mediaLinks: IMedia[] = [
    {
        href: LinksEnum.INSTAGRAM,
        icon: { name: 'instagram' },
        onClick: () => {
            gtagEventTrigger({ action: 'go_to_instagram', category: 'social' })
        },
    },
    {
        href: LinksEnum.TWITTER,
        icon: { name: 'x' },
        onClick: () => {
            gtagEventTrigger({ action: 'go_to_X', category: 'social' })
        },
    },
    {
        href: LinksEnum.FACEBOOK,
        icon: { name: 'facebook' },
        onClick: () => {
            gtagEventTrigger({ action: 'go_to_facebook', category: 'social' })
        },
    },
    {
        href: LinksEnum.YOUTUBE,
        icon: { name: 'youtube' },
        onClick: () => {
            gtagEventTrigger({ action: 'go_to_youtube', category: 'social' })
        },
    },
    {
        href: LinksEnum.TELEGRAM,
        icon: { name: 'telegram' },
        onClick: () => {
            gtagEventTrigger({ action: 'go_to_telegram', category: 'social' })
        },
    },
]

export const navigationLinks_2: { text: Dictionary; link: string; outside?: boolean }[] = [
    { text: 'footer.link_3', link: LinksEnum.PRIVACY },
    { text: 'footer.link_7', link: LinksEnum.TERMS },
    { text: 'footer.link_5', link: LinksEnum.BECOME_A_PARTNER, outside: true },
    { text: 'footer.link_6', link: LinksEnum.APPLY_AS_AN_ARTIST, outside: true },
]
