import { components, OptionProps } from 'react-select';
import React from 'react';
import { Button } from '@components/UIKit/Button/Button';
export const Option = (props: OptionProps) => components.Option && <components.Option {...props}>
            <Button id='default_button' size={'medium'} color={'Transparent'} icons={!props.isSelected ? undefined : {
    right: {
      name: 'check'
    }
  }}>
                {props.children}
            </Button>
        </components.Option>;