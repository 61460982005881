import React, { FC } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
interface Props {}
export const HrefLangLinks: FC<Props> = ({}) => {
  const router = useRouter();
  const currentPath = router.asPath;
  const lang = router.locale;
  return <Head data-sentry-element="Head" data-sentry-component="HrefLangLinks" data-sentry-source-file="HrefLangLinks.tsx">
            {process.env.NEXT_PUBLIC_ENV === 'prod' && <>
                    <link rel='alternate' hrefLang={lang} href={`${process.env.NEXT_PUBLIC_BASE_URL}/${lang}${currentPath}`} />
                    <link rel='canonical' href={`${process.env.NEXT_PUBLIC_BASE_URL}/${lang}${currentPath}`} />
                </>}
        </Head>;
};