import React, { FC } from 'react';
import { Avatar } from '@components/UIKit/Avatar/Avatar';
import { useProfile } from '@hooks/TRPC_Hooks/useProfile';
interface Props {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}
export const MyAvatar: FC<Props> = ({
  onClick
}) => {
  const {
    data: profile,
    isLoading: profileLoading
  } = useProfile();
  return <Avatar srcSet={[{
    height: 40,
    width: 40,
    minMedia: 0
  }]} profile={profile} isLoading={profileLoading} onClick={onClick} data-sentry-element="Avatar" data-sentry-component="MyAvatar" data-sentry-source-file="MyAvatar.tsx" />;
};