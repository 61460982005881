import React from 'react';
import cn from 'classnames';
import ReactSelect from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { DropdownIndicator } from './DropdownIndicator';
import { Option } from './Option';
import s from './select.module.scss';
export interface IOption<L = string, V = string> {
  label: L;
  value: V;
}
interface SelectProps<L = string, V = string> extends Omit<StateManagerProps, 'onChange'> {
  options: Array<IOption<L, V>>;
  changeHandler: (props: IOption<L, V>) => void;
  label?: React.ReactNode;
}
const IsOption = <L, V>(option: unknown): option is IOption<L, V> => 'value' in (option as IOption<L, V>) && 'label' in (option as IOption<L, V>);
export const Select = <L, V>({
  label,
  options,
  name,
  changeHandler,
  className,
  value,
  classNamePrefix,
  ...props
}: SelectProps<L, V>) => <div className={s.selectBox} data-sentry-component="Select" data-sentry-source-file="Select.tsx">
        {label && <div className={s.selectBox__label}>
                {label}
            </div>}
        <ReactSelect className={cn('_select', !!value && '_select_selected', props.menuPlacement === 'top' && '_select_top', className)} classNamePrefix={cn('_select', classNamePrefix)} components={{
    DropdownIndicator,
    Option
  }} options={options} name={name} instanceId={name} isSearchable={false} unstyled styles={{
    control: (base, props) => ({
      ...base,
      background: props.hasValue ? 'var(--white) !important' : 'var(--Bleached-Silk) !important'
    })
  }} onChange={newValue => {
    if (IsOption<L, V>(newValue) && changeHandler) {
      changeHandler(newValue);
    }
  }} value={value} {...props} data-sentry-element="ReactSelect" data-sentry-source-file="Select.tsx" />
    </div>;