import { ISrcSet } from '@helpers/types';
import cn from 'classnames';
import Link from 'next/link';
import React, { FC, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import s from './avatar.module.scss';
import { remotePath } from '@specs/constants';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { avatar, profile } from '@specs/specs';
import { LinksEnum } from '@specs/navigation';
import { CloudImg } from '../CloudImg/CloudImg';
import { useTranslation } from '@hooks/useTranslation';
import { OptimizedImage } from '@components/OptimizedImage/OptimizedImage';
interface UserAvatarProps extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src' | 'srcSet' | 'alt' | 'onClick' | 'width' | 'height'> {
  alt?: string;
  srcSet: Omit<ISrcSet, 'avatarId'>[];
  shortId?: profile['shortid'];
  profile: Pick<profile, 'shortid'> & {
    avatar: avatar | null | undefined;
  } | null | undefined;
  isLoading: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}
export const Avatar: FC<UserAvatarProps> = ({
  srcSet,
  profile,
  shortId,
  isLoading,
  alt = 'user avatar',
  className,
  onClick,
  ...props
}) => {
  const {
    translate: tr
  } = useTranslation();
  const [imageSource, setImageSource] = useState<{
    cloud: boolean | undefined;
    cdn: boolean | undefined;
  }>({
    cloud: undefined,
    cdn: undefined
  });
  if (isLoading) return <div className={s.avatar}>
            <Skeleton containerClassName={s.avatar__loader} width={'100%'} height={'100%'} baseColor={'var(--Thamar-Black-5)'} highlightColor={'var(--Thamar-Black-40)'} circle={true} />
        </div>;
  if (!profile) return null;
  const showLogo = !profile.avatar?.id || imageSource.cloud === false && imageSource.cdn === false;
  return <Link className={cn(s.avatar, showLogo && s.avatar_default, className)} href={{
    pathname: LinksEnum.USER,
    query: {
      slug: profile.shortid
    }
  }} onClick={onClick} data-sentry-element="Link" data-sentry-component="Avatar" data-sentry-source-file="Avatar.tsx">
            {!showLogo ? <>
                    <OptimizedImage path={remotePath} alt={profile.shortid} title={tr({
        id: 'artists.card.image.title'
      }, {
        shortId: profile.shortid
      })} cdnId={profile.avatar?.id ?? ''} resolutions={1} onError={() => {
        setImageSource(prev => ({
          ...prev,
          cdn: false
        }));
      }} onLoad={() => {
        setImageSource(prev => ({
          ...prev,
          cdn: true
        }));
      }} boxClassName={s.avatar__image} />
                    {imageSource.cloud !== false && <CloudImg className={s.avatar__image} alt={alt} title={tr({
        id: 'artists.card.image.title'
      }, {
        shortId: profile.shortid
      })} srcSet={srcSet.map(item => ({
        ...item,
        avatarId: profile.avatar?.id
      }))} onError={_ => {
        setImageSource(prev => ({
          ...prev,
          cloud: false
        }));
      }} onLoad={_ => {
        setImageSource(prev => ({
          ...prev,
          cloud: true
        }));
      }} {...props} />}
                </> : <SpriteIcon className={s.avatar__icon} name={'avatar_default'} color={'--Wild-Dove'} />}
        </Link>;
};