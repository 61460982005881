import React, { FC, useState } from 'react';
import s from './footer.module.scss';
import cn from 'classnames';
import { Dictionary, translateType, useTranslation } from '@hooks/useTranslation';
import Link from 'next/link';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { Button } from '@components/UIKit/Button/Button';
import { IOption, Select } from '@components/UIKit/Select/Select';
import { mediaLinks } from '@specs/footerHelper';
import telegramChats from '@assets/telegram-chats.json';
import { CdnPath } from '@specs/cdn';
import parse from 'html-react-parser';
import { Img } from '@components/Img';
interface Props {}
const getOptionsList = (tr: translateType): Array<IOption & {
  code: string;
}> => telegramChats.map(el => ({
  value: el.href,
  label: tr({
    id: el.translate_key as Dictionary
  }),
  code: el.code
}));
export const Footer: FC<Props> = ({}) => {
  const {
    translate: tr
  } = useTranslation();
  const optionsList = getOptionsList(tr);
  const [selectedTelegramChat, setSelectedTelegramChat] = useState<IOption | undefined>(typeof window === 'undefined' ? optionsList[0] : optionsList.find(el => window.navigator.language.includes(el.code) ?? optionsList[0]));
  const goToTelegramHandler = async () => {
    if (typeof selectedTelegramChat === 'undefined') return;
    window.open(selectedTelegramChat.value, '_blank', 'noreferrer');
  };
  const changeHandler = ({
    value
  }: IOption) => {
    setSelectedTelegramChat(optionsList.find(el => el.value === value));
  };
  return <footer className={s.footer} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
            <div className={'_container'}>
                <div className={s.footer__body}>
                    <div className={cn(s.footer__block, s.footerTelegram)}>
                        <Img boxClassName={s.footerTelegram__bg} path={CdnPath.footer_decore} cdnId={'footer_v2'} alt={'footer_v2'} data-sentry-element="Img" data-sentry-source-file="Footer.tsx" />
                        <div className={s.footerTelegram__body}>
                            <div className={cn(s.footerTelegram__title)}>
                                {parse(tr({
                id: 'footer.telegram.title'
              }))}
                            </div>
                            <div className={s.footerTelegram__row}>
                                <Select options={optionsList} changeHandler={changeHandler} placeholder={tr({
                id: 'footer.telegram.placeholder'
              })} value={selectedTelegramChat} data-sentry-element="Select" data-sentry-source-file="Footer.tsx" />
                                <Button id="go_to_chat_button" color={'Black'} size={'medium'} onClick={goToTelegramHandler} disabled={typeof selectedTelegramChat === 'undefined'} icons={{
                right: {
                  name: 'share'
                }
              }} data-sentry-element="Button" data-sentry-source-file="Footer.tsx">
                                    {tr({
                  id: 'btn.go.to.chat'
                })}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={cn(s.footer__block, s.footerBottom)}>
                        <div className={s.footerBottom__box}>
                            <div className={s.footerBottom__socials}>
                                {mediaLinks.map(({
                icon,
                href,
                onClick
              }) => <Link key={href} className={s.footerBottom__item} href={href} target="_blank" rel="noreferrer" onClick={onClick}>
                                        <SpriteIcon {...icon} />
                                    </Link>)}
                            </div>
                            <div className={s.footerBottom__copyright}>
                                <SpriteIcon name={'logo'} data-sentry-element="SpriteIcon" data-sentry-source-file="Footer.tsx" />
                                {' '}
                                <span>
                                    R.Toys © Copyright, {new Date().getFullYear()}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>;
};