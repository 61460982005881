import React, { FC } from 'react';
import { ISrcSet } from '@helpers/types';
import { useTranslation } from '@hooks/useTranslation';
interface ImgProps extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src' | 'srcSet'> {
  resolutions?: 1 | 2 | 3;
  srcSet: ISrcSet[];
  shortId?: string;
}
const CloudImgMemo: FC<ImgProps> = ({
  alt,
  srcSet,
  resolutions = 3,
  shortId,
  ...props
}) => {
  const {
    translate: tr
  } = useTranslation();
  return <>
            <picture data-sentry-element="picture" data-sentry-source-file="CloudImg.tsx">
                {srcSet?.map(({
        height,
        minMedia,
        width,
        avatarId
      }) => <source key={minMedia} srcSet={generateSrcset(avatarId, width, height, resolutions)} media={`(min-width: ${minMedia}px)`} />)}
                <img alt={alt} title={tr({
        id: 'artists.card.image.title'
      }, {
        shortId
      })} {...props} />
            </picture>
        </>;
};
export const CloudImg = React.memo(CloudImgMemo);
const createSrc = (avatarId: string, width: string | number | undefined, height: string | number | undefined) => {
  const widthParam = width ? `w=${width}` : undefined;
  const heightParam = height ? `h=${height}` : undefined;
  const splitParams = [widthParam, heightParam].filter(el => el !== undefined).join(',');
  return `https://imagedelivery.net/${process.env.NEXT_PUBLIC_CLOUD_ACCOUNT_HASH}/${avatarId}/${splitParams},format=auto`;
};
const generateSrcset = (avatarId: string | undefined, width: string | number | undefined, height: string | number | undefined, resolutions = 3) => {
  if (!avatarId) return;
  let srcset = '';
  for (let i = 1; i <= resolutions; i++) {
    const dprDecorator = i > 1 ? `,dpr=${i} ${i}x, ` : ', ';
    srcset += `${createSrc(avatarId, width, height)}${dprDecorator}`;
  }
  return srcset;
};